import React from 'react'
import './Modal.scss'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination,EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/bundle";

const Modal = ({project,closeModal}) => {
  return (
    <div className="modal-container">
        <div className="modal-body">
          <div className="modal-section1">
          <div className="swiper-container">
            <Swiper
                modules={[Autoplay, Pagination,EffectFade]}
                spaceBetween={50}
                autoplay={{
                  delay: 1000,
                }}
                loop={true}
                speed= {4000}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                slidesPerView={1}
                direction="horizontal"
             
              >
                <SwiperSlide>
                  <img
                    className="img-fluid"
                    src={project.img1}
                    alt={`${project.img1}`}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="img-fluid"
                    src={project.img2}
                    alt={`${project.img2}`}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="img-fluid"
                    src={project.img3}
                    alt={`${project.img3}`}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="img-fluid"
                    src={project.img4}
                    alt={`${project.img4}`}
                  />
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="modal-text-container">
              <p>{project.description}</p>
              <a href={project.url} style={{"--clr1":"#FFF","--clr2":"#cf0a8e","--clr-bg":"#cf0a8d6b","fontSize":".9rem","width":"50%","margin":".3rem auto"}} className='custom-btn'>
                  VISIT WEBSITE
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  </a>     
            </div>
          </div>
    
            <button style={{"zIndex":"8"}} onClick={() => closeModal()} className= {`ham-btn isActive`}>
              
              <span></span>
                <span></span>
                  <span></span>
            </button>
            <div className="modal-techs">
              <h2>Technologies Used</h2>
              <ul>
                {project.usedTechs.map(tech => {
                  return <li key={tech}>{tech}</li>
                })}
              </ul>
            </div>
        </div>
    </div>
  )
}

export default Modal
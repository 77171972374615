import React from 'react'
import Project from '../project/Project'
import './PortfolioSection.scss'
import { projects } from './projects'

const PortfolioSection = ({ref3}) => {
  const [projectsData] = React.useState(projects)
  return (
   <div ref={ref3} id='portfolio' className="portfolio-container">
    <div className="portfolio-header">
        <h1 className="portfolio-title">Some Of My Work</h1>
    </div>
    <div className="portfolio-body">
      {
        projectsData.map(project => {
          return <Project key={project.id} title={project.title} project={project} bgImg={project.img1} id={project.id}  />
        })
      }
    </div>
   </div>
  )
}

export default PortfolioSection
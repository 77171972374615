import React from 'react'
import './Navbar.scss'


const Navbar = ({aboutVisible,portVisible,contactVisible}) => {
  const [hamToggled,setHamToggled] = React.useState(false);


  return (
        <div className={`nav-container ${hamToggled ? "navToggled":""}`}>
          <div className="logo">
            <a href="/">
                <img src="./kareem_shono_nav_logo.svg" alt="kshono navigation logo" />
            </a>
          </div>
           
          <nav className="navbar">
          <button onClick={() => setHamToggled(!hamToggled)} className= {`ham-btn ${hamToggled ? 'isActive':''}`}>
             
              <span></span>
                <span></span>
                  <span></span>
            </button>
            <ul className="navlist">
                <li className="nav-item">
                    <a href="#home" className={`nav-link `}>Home</a>
                </li>
                <li className="nav-item">
                    <a href="#about" className={`nav-link ${aboutVisible ? "active":""} `}>About</a>
                </li>
                <li className="nav-item">
                    <a href="#portfolio" className={`nav-link ${portVisible ? "active":""}`}>Portfolio</a>
                </li>
                <li className="nav-item">
                    <a href="#contact" className={`nav-link ${contactVisible ? "active":""}`}>Contact</a>
                </li>
            </ul>
            
          </nav>
        </div>
  )
}

export default Navbar
export const projects = [
    {id:0, title:"alfacorp",
    img1:"./pictures/alfa/alfacorp_kshono_1.webp",
    img2:"./pictures/alfa/alfacorp_kshono_2.webp",
    img3:"./pictures/alfa/alfacorp_kshono_3.webp",
    img4:"./pictures/alfa/alfacorp_kshono_4.webp",
    usedTechs:['bootstrap','javascript','sass','adobe xd','jquery'],
    description:'Static website for alfa accounting and finance company',
    url:'https://alfacorp.ca/'
         },
   
    {id:1,
        title:"HomeGateway Notary Services",
    img1:"./pictures/homegateway/homegateway_kshono_1.webp",
    img2:"./pictures/homegateway/homegateway_kshono_2.webp",
    img3:"./pictures/homegateway/homegateway_kshono_3.webp",
    img4:"./pictures/homegateway/homegateway_kshono_4.webp",
    usedTechs:['react',"figma",'sass'],
    description:'Website for leading company in UAE for documents processing ',
    url:'https://homegateway.ae/'
 },
    {id:2,
        title:"bermuda electronic store",
    img1:"./pictures/bes-store/bes_kshono_2.webp",
    img2:"./pictures/bes-store/bes_kshono_1.webp",
    img3:"./pictures/bes-store/bes_kshono_3.webp",
    img4:"./pictures/bes-store/bes_kshono_4.webp",
    usedTechs:['react','redux','sass','firebase'],
    description:'Full Ecommerce store with firebase Authentication , dynamic shopping cart, stripe payment integration and more...',
    url:'https://bes-store.kshono.com/'
 }
 , {id:3,
    title:"Fast Chat Application",
    img1:"./pictures/fastchat/fc-1.webp",
    img2:"./pictures/fastchat/fc-2.webp", 
    img3:"./pictures/fastchat/fc-3.webp", 
    img4:"./pictures/fastchat/fc-4.webp",
    usedTechs:['react','redux','firebase','sass','figma'],
    description:'Fullstack chatting web application, provides fast,realtime chatting',
    url:'https://fastchat.kshono.com'
 },
  
    {id:4,
        title:"smart brain app",
        img1:"./pictures/smartbrain/smart_brain_app_kshono_1.webp",
        img2:"./pictures/smartbrain/smart_brain_app_kshono_2.webp",
        img3:"./pictures/smartbrain/smart_brain_app_kshono_3.webp",
        img4:"./pictures/smartbrain/smart_brain_app_kshono_4.webp",
        usedTechs:['react','redux','firebase',"ai model api","figma"],
        description:'Web application built from scratch, it uses advanced ai model Api for detecting faces in images provided, along with authentication and saving data',
        url:'https://smart-brain-v2.vercel.app/'
         },{id:5,
            title:"Labtix tech company",
            img1:"./pictures/labtix/labtix_kshono.webp",
            img2:"./pictures/labtix/labtix_kshono2.webp", 
            img3:"./pictures/labtix/labtix_kshono3.webp", 
            img4:"./pictures/labtix/labtix_kshono4.webp",
            usedTechs:['react','three.js','react-spring','sass','adobe xd'],
            description:'3D, animated website with 2 languages built for information technology company ',
            url:'https://labtix.sy' },
            {id:6,
                title:"games blog",
                img1:"./pictures/gamesblog/games_blog_kshono_1.webp",
                img2:"./pictures/gamesblog/games_blog_kshono_2.webp",
                img3:"./pictures/gamesblog/games_blog_kshono_3.webp",
                img4:"./pictures/gamesblog/games_blog_kshono_4.webp",
                usedTechs:['react','sass'],
                description:'Very simple blog, built with react , adding and rendering posts in client side only',
            url:'https://kareemshowno.github.io/Games-Blog/#/'
             },
]
import React from 'react'
import "./Project.scss"
import Modal from '../modal/Modal';

const Project = ({id, title,bgImg,project}) => {
  const [showModal,setShowModal] = React.useState({
    show:false,
    project:null
  });

  const openModal = (e) => {
    setShowModal({
      show:true,
      project
    })
  }
  const closeModal = () => {
    setShowModal({
      show:false,
    project:null
    })
  }
  return (<>
      <div  className="project-container">
        <div className="project-img-container">
        <img src={bgImg} alt="" />
        </div>
        
      <div className="project-overlay">
        <h3>{title}</h3>
        <p>{project.description}</p>
      <div className='btns'>
      <a onClick={() => openModal()}   rel="noreferrer"  style={{"color":"#61dafb","--clr1":"#FFF","--clr2":"#5bb8e1","--clr-bg":"#5bb9e18f","fontSize":".9rem"}} className='custom-btn'>
                  LEARN MORE...
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  </a>     
      <a href={project.url} style={{"--clr1":"#FFF","--clr2":"#cf0a8e","--clr-bg":"#cf0a8d6b","fontSize":".9rem"}} className='custom-btn'>
                  VISIT WEBSITE
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  </a>     
      </div>

      </div>
      </div>
      {showModal.show ? <Modal closeModal={closeModal} project={project} /> : null}
      </>
   
  )
}

export default Project

import './App.scss';
import AboutSection from './components/aboutsection/AboutSection';
import Contact from './components/contactsection/Contact';
import LandingSection from './components/landingsection/LandingSection';
import Navbar from './components/navbar/Navbar';
import PortfolioSection from './components/portfolilosection/PortfolioSection';
import { useInView } from "react-intersection-observer";
function App() {
  const {ref:aboutRef,inView:aboutVisible} = useInView()
  const {ref:portRef,inView:portVisible} = useInView()
  const {ref:contactRef,inView:contactVisible} = useInView()
  return (
    <>
       <LandingSection />
       <Navbar aboutVisible={aboutVisible} portVisible={portVisible} contactVisible={contactVisible} />
       <AboutSection ref2={aboutRef} />
       <PortfolioSection ref3={portRef} />
       <Contact ref4={contactRef} />
    </>
 
  )
}

export default App;

import React from 'react'
import './Contact.scss'
import emailjs from '@emailjs/browser';


const Contact = ({ref4}) => {
    const [fullname,setName] = React.useState('');
    const [email,setEmail] = React.useState('');
    const [message,setMessage] = React.useState('');
    const [reqMsg,setReqMsg] = React.useState("");
    const form = React.useRef();
  const sendEmail=(e) => {
      e.preventDefault();
      
    emailjs.sendForm('service_p5xa4jm', 'template_i451bkm', form.current, 'user_YQzd64oLgnX5xpC46Tp5s')
    .then((result) => {
        result.text === "OK"? setReqMsg("Message sent successfully!"):setReqMsg(`${result.text}`)
        
        
    }, (error) => {
        setReqMsg(`there was an error: ${error.text}`)
    });
   setName('')
   setEmail('')
   setMessage('')
  
  }



  return (
    <div id="contact"  className="contact-container">
        <div className="contact-header">
            <h1>Contact</h1>
            <h4>Have a question or want us to work together ?</h4>
        </div>

        <div  className="contact-body">
        <div className=" form-container  ">
<form ref={form} onSubmit={sendEmail} className="  custom-form ">
<p className='req-msg'>{reqMsg}</p>
    <div ref={ref4} className="form-group ">
        <label className='form-label' htmlFor="fullname">Full Name:</label>
        <input value={fullname} onChange={(e) => setName(e.target.value)} required className='form-control' type="text" name="fullname" id="fullname" />
    </div>
    
    <div className="form-group ">
        <label className='form-label' htmlFor="email">Email Adress:</label>
        <input value={email} onChange={(e)=>setEmail(e.target.value)} required className='form-control' type="email" name="email" id="email" />
    </div>
    <div className="form-group ">
        <label className='form-label' htmlFor="message">Message :</label>
        <textarea value={message} onChange={(e)=>setMessage(e.target.value)} required className='form-control text-ari' type="textarea" name="message" id="message" />
   
    </div>
    <div className="btn-container">
    <button type='submit' style={{"--clr1":"#FFF","--clr2":"#cf0a8e","--clr-bg":"#cf0a8d6b","fontSize":".9rem","cursor":"pointer"}} className='custom-btn'>
      SEND MESSAGE
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      </button>    
    </div>
    
</form>
</div> 
<div className="contact-info">
            <div className='col-12'>
                <img src="./kshono_footer2.svg" alt="kshono logo" />
            </div>
            <div className="icons">
            <div className="col-4 phone-num  ">
            <img  src="./phone.svg" alt="phone" /> <span className='num-span' style={{"marginTop":"7px"}}>+964 751 124 1004 </span> 
            </div>
               <div className="col-4 github-acc  ">
              <a href="https://github.com/kareemshowno" target="_blank" rel="noopener noreferrer"> 
              <img  src="./github.svg" alt="github" /> <span>kareem showno</span> </a>
               </div>
               <div className="col-4 facebook-acc  ">
                   <a href="https://facebook.com/kareemshono" target="_blank" rel="noopener noreferrer">
                   <img  src="./facebook.svg" alt="facebook" /> <span>kareem shono</span> 
                   </a>
               </div>
               <div className="col-4 linkedin-acc  ">
               <a href="https://www.linkedin.com/in/kareem-shono-0b08a41a9/" target="_blank" rel="noopener noreferrer">
               <img  src="./linkedin.svg" alt="linkedin" /> <span>kareem shono</span> 
               </a>
                   
               </div>
               <div className="col-4 email-acc  ">
                   <a href="mailto:info@kshono.com" target="_blank" rel="noopener noreferrer">
                       <img  src="./mail.svg" alt="mail" /> <span>info@kshono.com</span> 
                   </a>
               </div>
            </div>
            
                
            
            </div>

        </div>


        <div className="contact-footer">

         <hr className='copy-divider' />
          <p className='copyr'>      kareemshono&copy;2022</p>
        </div>
    </div>
  )
}

export default Contact






 









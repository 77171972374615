import React from 'react'
import './LandingSection.scss'


const LandingSection = () => {
  return (
    <div id="home" className="container">
        <div className="column-hero-text">
            <h1 className='hero-text'>Hello, i'm kareem shono <br />i'm a <span className='fwd'>front end web developer</span> </h1>
            <a style={{"width":"30%","textAlign":"center","--clr1":"#FFF","--clr2":"#5bb8e1","--clr-bg":"#5bb9e18f"}} href='#about' className='custom-btn'>
                  About me
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  </a>     
        </div>
        <div className="column-hero-svg">
            <object className='svg-banner' aria-label="svg animtaed picture" data="./kshono-hero2.svg" type="image/svg+xml"></object>
        </div>
    </div>
  )
}

export default LandingSection